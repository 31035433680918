import * as S from './styles'

import {
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogLabel,
} from '@reach/alert-dialog'
import {
  Pagination,
  SectionUsers,
  SectionUsersList,
  TabList,
} from '../../../components'
import {
  AssociatedMasterDealer,
  Pagination as PaginationType,
  UserProfile,
} from '@monorepo/interfaces'
import {
  DealerType,
  except,
  http,
  httpUserInfo,
  usePaginationSearchParams,
  useQueryParams,
} from '@monorepo/infra'
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'

import { Icon } from '@monorepo/components'
import {
  DashboardBackButton,
  PaginationDetails,
  ExportXlsLink,
} from '../../atoms'
import { SearchTerm } from '../../molecules'
import { UserForm } from './forms/userForm'
import { colors } from '@monorepo/theme'
import useTextTranslation from '@monorepo/infra/build/locales/i18next/useLocation'
import { IUserForm } from './forms/userForm'
import axios from 'axios'
import * as TreeHelper from '../../helpers/user'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { useKeycloak } from '@react-keycloak/web'
import { KeycloakHelper } from '@monorepo/infra'
import moment from 'moment'

type UsersFetch = PaginationType & {
  data: UserProfile[]
}

const Users = (): JSX.Element => {
  const directoryBaseUrl = '/user/directory'
  const pageURLKey = 'p'
  const searchURLKey = 's'

  const queryParams = useQueryParams()

  const [isManageDialogOpened, setIsManageDialogOpened] = useState(false)
  const manageCancelRef = useRef(null)

  const [searchTerm, setSearchTerm] = useState(
    queryParams.get(searchURLKey) ?? ''
  )
  const { t } = useTextTranslation('admin')

  const { apiSearchParams, handlePageChange, handlePrevPage, handleNextPage } =
    usePaginationSearchParams({
      pageAPIKey: 'pageNum',
      pageURLKey,
      searchAPIKey: 'search',
      searchURLKey,
      searchTerm,
    })

  const [users, setUsers] = useState<UsersFetch>()
  const [userToEdit, setUserToEdit] = useState<IUserForm>()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  // const [currentPage, setCurrentPage] = useState(1)
  const previousApiUrl = useRef<string | undefined>(undefined)

  const getUsers = async () => {
    setLoading(true)

    const activeTab = queryParams.get('activeTab')
    const search = queryParams.get('s')

    const page = (() => {
      const pageStr = queryParams.get('p')

      if (!pageStr) {
        return 1
      }

      const pageInt = parseInt(pageStr)

      if (!pageInt || pageInt < 1) {
        return 1
      }

      return pageInt
    })()

    setPage(page)

    const searchParams = new URLSearchParams()
    searchParams.append('page', page.toString())
    searchParams.append('pageSize', '20')
    searchParams.append('withDealers', 'true')

    if (activeTab && activeTab.trim().length !== 0) {
      searchParams.append('groups', activeTab)
    }

    if (search && search.trim().length !== 0) {
      searchParams.append('search', search)
    }

    const apiURL = `${directoryBaseUrl}?${searchParams.toString()}`
    // const url =
    //   activeTab !== 'all' && activeTab !== null
    //     ? `/user/directory?page=${currentPage}&pageSize=10&groups=${activeTab}`
    //     : `/user/directory?page=${currentPage}&pageSize=10`

    if (previousApiUrl.current === apiURL) {
      return
    }

    previousApiUrl.current = apiURL
    const usersList = await httpUserInfo.get<UsersFetch>({
      url: apiURL,
    })

    setUsers(usersList.data)
    setLoading(false)
  }

  const handlePageChangeLocal = (page: number) => {
    handlePageChange(page)
  }

  useEffect(() => {
    getUsers()
  }, [queryParams, apiSearchParams])

  const toggleModal = useCallback(() => {
    setIsManageDialogOpened((isOpened) => !isOpened)
  }, [])

  const { keycloak } = useKeycloak()

  const handleOpenModalEditUser = (index: number) => {
    const data = users?.data[index] as UserProfile
    const userForm: IUserForm = {
      userId: data?._id as string,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      dealerTypes: data?.dealerTypes || [],
      enabled: data?.enabled || false,
      associatedMasterDealers:
        data?.dealer?.associatedMasterDealers?.map(
          (item) => item.masterDealerId
        ) || [],
      groups: data?.groups,
    }
    setUserToEdit(userForm)
    setIsManageDialogOpened(true)
  }

  // const { data, ...pagination } = users
  // pagination.page = Number(1)
  const handleClearSearchTerm = () => setSearchTerm('')

  const handleDeleteUser = async (email: string) => {
    const result = window.confirm('Are you sure?')
    if (!result) {
      return
    }

    try {
      await httpUserInfo.delete({ url: `/dealer/byuser/${email}` })
      await httpUserInfo.delete({ url: `/user/${email}` })
      document.location.reload()
    } catch (err) {
      console.error(err)
    }
  }

  const isAssociatedMasterDealerChanged = (
    oldValue?: AssociatedMasterDealer[],
    newValue?: string[]
  ): { isChanged: boolean; oldValue: string[]; newValue: string[] } => {
    const _oldValue = oldValue?.map((item) => item.masterDealerId) || []
    const _newValue = Array.isArray(newValue) ? newValue : []
    const isChanged =
      except(_oldValue, _newValue).length > 0 ||
      except(_newValue, _oldValue).length > 0 ||
      (_oldValue.length > 0 &&
        _newValue.length > 0 &&
        _oldValue[0] !== _newValue[0])
    return { isChanged, oldValue: _oldValue, newValue: _newValue }
  }

  const isGroupsChanged = (
    oldValue?: string[],
    newValue?: string[]
  ): { isChanged: boolean; oldValue: string[]; newValue: string[] } => {
    const _oldValue = Array.isArray(oldValue) ? oldValue : []
    const _newValue = Array.isArray(newValue) ? newValue : []
    const isChanged =
      except(_oldValue, _newValue).length > 0 ||
      except(_newValue, _oldValue).length > 0
    return { isChanged, oldValue: _oldValue, newValue: _newValue }
  }

  const handleUserActivity = async (data: IUserForm) => {
    const userChanges = []
    const previusData = users?.data.find((u) => u._id === data.userId)
    if (previusData?.dealerTypes !== data.dealerTypes) {
      Object.values(DealerType).filter((d) => {
        if (
          !previusData?.dealerTypes?.includes(d) &&
          data?.dealerTypes?.includes(d)
        ) {
          userChanges.push({ key: d, previusValue: false, currentValue: true })
        }
        if (
          previusData?.dealerTypes?.includes(d) &&
          !data?.dealerTypes?.includes(d)
        ) {
          userChanges.push({ key: d, previusValue: true, currentValue: false })
        }
      })
    }
    if (previusData?.enabled !== data.enabled) {
      userChanges.push({
        key: 'enable',
        previusValue: Boolean(previusData?.enabled),
        currentValue: Boolean(data.enabled),
      })
    }

    const {
      isChanged: _isAssociatedMasterDealerChanged,
      oldValue: previousAssociatedMasterDealers,
      newValue: currentAssociatedMasterDealers,
    } = isAssociatedMasterDealerChanged(
      previusData?.dealer?.associatedMasterDealers,
      data.associatedMasterDealers
    )
    if (_isAssociatedMasterDealerChanged) {
      userChanges.push({
        key: 'associatedMasterDealers',
        previusValue: `[${previousAssociatedMasterDealers
          .map((item) => `"${item}"`)
          .toString()}]`,
        currentValue: `[${currentAssociatedMasterDealers
          .map((item) => `"${item}"`)
          .toString()}]`,
      })
    }
    const {
      isChanged: _isGroupsChanged,
      oldValue: previousGroups,
      newValue: currentGroups,
    } = isGroupsChanged(previusData?.groups, data.groups)
    if (_isGroupsChanged) {
      userChanges.push({
        key: 'groups',
        previusValue: `[${previousGroups
          .map((item) => `"${item}"`)
          .toString()}]`,
        currentValue: `[${currentGroups
          .map((item) => `"${item}"`)
          .toString()}]`,
      })
    }

    if (userChanges.length > 0) {
      return await http.post({
        url: '/log',
        data: {
          userActivity: {
            changes: userChanges,
            userId: data.userId,
          },
          type: 3,
        },
        headers: {
          Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
          'content-type': 'application/json',
        },
      })
    }
  }

  const handleEditUser = async (data: IUserForm) => {
    try {
      const userData = {
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dealerTypes: data.dealerTypes,
        enabled: data.enabled,
        groups: data.groups,
      }
      handleUserActivity(data)

      await httpUserInfo.patch({
        url: `/user/${data.userId}`,
        data: userData,
      })

      const dealer = users?.data.find((u) => u._id === data.userId)?.dealer
      if (dealer) {
        const { isChanged, newValue } = isAssociatedMasterDealerChanged(
          dealer.associatedMasterDealers,
          data.associatedMasterDealers
        )
        if (isChanged) {
          await httpUserInfo.patch({
            url: `/dealer/${dealer._id}`,
            data: {
              _id: dealer._id,
              userId: dealer.userId,
              associatedMasterDealers: newValue.map((item) => ({
                masterDealerId: item,
              })),
              hasCoop: dealer.hasCoop,
              channels: dealer.channels,
            },
          })
        }
      }

      setIsManageDialogOpened(false)
      document.location.reload()
    } catch (err) {
      console.error(err)
    }
  }

  const handleExportXls = async () => {
    try {
      const responses = await Promise.all([
        axios.get('/masterdealer/tree', {
          baseURL: process.env.REACT_APP_REST_USER_BASE_URL,
        }),
        axios.get('/United/DealerUniqueEmails', {
          baseURL: process.env.REACT_APP_TX_BASE_URL,
        }),
      ])

      if (Array.isArray(responses) && responses.length > 1) {
        const data = TreeHelper.getTreeDataToExport(
          responses[0].data,
          responses[1].data?.DealerEmails
        )
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
        const fileExtension = '.xlsx'
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const outputData = new Blob([excelBuffer], { type: fileType })
        const dateStr = moment().format("MMDDYYYY")
        FileSaver.saveAs(outputData, `Directory${dateStr}${fileExtension}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <S.Main>
      <DashboardBackButton redirectURL="/admin" text="Back to Admin" />
      <SectionUsers title="User Management" />
      <S.PageActions>
        <TabList
          tabKey="activeTab"
          tabs={[
            { description: 'All', tabName: 'all' },
            { description: 'DirecTV', tabName: 'directvAdmin' },
            { description: 'Vendors', tabName: 'vendor' },
            { description: 'Zoners', tabName: 'zoner' },
          ]}
        />

        <S.ActionButtonsContainer>
          <ExportXlsLink onClick={handleExportXls} />
          {isManageDialogOpened && (
            <S.AlertDialogOverlay
              onDismiss={toggleModal}
              leastDestructiveRef={manageCancelRef}
            >
              <S.AlertDialog
                onDismiss={toggleModal}
                leastDestructiveRef={manageCancelRef}
              >
                <AlertDialogContent
                  style={{
                    background: '#fff',
                    padding: '0 20px',
                    margin: '1vh auto',
                  }}
                >
                  <AlertDialogLabel></AlertDialogLabel>
                  <AlertDialogDescription>
                    <UserForm
                      close={toggleModal}
                      actionForm={handleEditUser}
                      initialValues={userToEdit}
                    />
                  </AlertDialogDescription>
                </AlertDialogContent>
              </S.AlertDialog>
            </S.AlertDialogOverlay>
          )}
        </S.ActionButtonsContainer>
      </S.PageActions>
      <S.Separator />
      <S.SearchFiltersContainer>
        <S.Search
          placeholder={t('section_users_list.input_placeholder')}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
        <SearchTerm searchTerm={searchTerm} onClear={handleClearSearchTerm} />
        <PaginationDetails
          totalItems={users?.totalCount as number}
          totalPages={users?.totalPages as number}
          itemsPerPage={users?.pageSize as number}
          pageNumber={users?.page as number}
          onClickPrev={handlePrevPage}
          onClickNext={handleNextPage}
        />
      </S.SearchFiltersContainer>
      <SectionUsersList
        loading={loading}
        users={users?.data as UserProfile[]}
        handleEditUser={handleOpenModalEditUser}
        handleDeleteUser={handleDeleteUser}
      />
      <S.PaginationContainer>
        <Pagination
          onPageChange={handlePageChangeLocal}
          totalCount={users?.totalCount as number}
          page={page}
          pageSize={users?.pageSize as number}
          totalPages={users?.totalPages as number}
        />
      </S.PaginationContainer>
    </S.Main>
  )
}

export default Users
