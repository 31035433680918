import { IconsType } from '@monorepo/components'
import * as S from './styles'
import { MouseEvent } from 'react'
import moment from 'moment'

interface Card {
  title: string
  link: string
  iconName: keyof typeof IconsType
  external?: boolean
  handleDownloadClick?: (link: string, event?: MouseEvent) => Promise<void>
}

interface CardGroup {
  subtitle: string
  cards: Card[]
}

interface DealerFileMetaDataSchema {
  uploadDate?: string
}

interface DealerFileMetaData {
  uploadDate: Date | null
}

const mapSchemaToDealerFileMetaData = (
  schema: DealerFileMetaDataSchema
): DealerFileMetaData => {
  return {
    uploadDate: tryDeserializeUploadDate(schema.uploadDate),
  }
}

const tryDeserializeUploadDate = (dateStr?: string): Date | null => {
  if (!dateStr || dateStr.trim().length === 0) {
    return null
  }

  const momentUSDate = moment(dateStr, 'MM-DD-YYYY')

  if (momentUSDate.isValid()) {
    return momentUSDate.toDate()
  }

  const momentDate = moment(dateStr)

  return momentDate.isValid() ? momentDate.toDate() : null
}

const loadDealerFileMetaDataAsync =
  async (): Promise<DealerFileMetaData | null> => {
    const dealerFileMetaDataUrl =
      `https://themarketingzoneblob.blob.core.windows.net/dealerfile/metadata.json?v=${Date.now()}`

    try {
      const dealerFileMetaDataResponse = await fetch(dealerFileMetaDataUrl)

      if (!dealerFileMetaDataResponse.ok) {
        return null
      }

      const dealerFileMetaDataSchema = await dealerFileMetaDataResponse.json()
      return mapSchemaToDealerFileMetaData(dealerFileMetaDataSchema)
    } catch (error) {
      console.error(error)
    }

    return null
  }

const getDealerFileDownloadFileName = (uploadDate: Date): string => {
  const dateStr = moment(uploadDate).format("MMDDYYYY")
  return `DealerFile${dateStr}.csv`
}

const getDealerFileDownloadFileNameAsync = async (): Promise<string> => {
  const dealerFileMetaData = await loadDealerFileMetaDataAsync()
  return getDealerFileDownloadFileName(
    dealerFileMetaData?.uploadDate ?? new Date()
  )
}

const getMenuCards = (
  handleDownloadClickAsync?: (link: string, event?: MouseEvent) => Promise<void>
): CardGroup[] => {
  return [
    {
      subtitle: 'People',
      cards: [
        {
          title: 'Directory',
          iconName: 'PersonCropRectangleStack',
          link: '/admin/directory',
        },
        {
          title: 'Export Dealer File',
          iconName: 'ExportIcon',
          link: `https://themarketingzoneblob.blob.core.windows.net/dealerfile/DealerFile.csv?v=${Date.now()}`,
          external: true,
          handleDownloadClick: handleDownloadClickAsync,
        },
      ],
    },
    {
      subtitle: 'Storefront Controls',
      cards: [
        {
          title: 'Resources',
          iconName: 'BooksVertical',
          link: '/admin/resources',
        },
        {
          title: 'New User Pop-Up',
          iconName: 'Megaphone',
          link: '/admin/pop-ups',
        },
        {
          title: 'Message Blasts',
          iconName: 'Envelope',
          link: '/admin/message-blasts',
        },
        {
          title: 'Homepage Image Update',
          iconName: 'HomepageImage',
          link: '/admin/homepage-image',
        },
      ],
    },
  ]
}

const SectionAdminMenus: React.FC = () => {
  const handleDownloadDealerFileClickAsync = async (
    link: string,
    event?: MouseEvent
  ): Promise<void> => {
    try {
      event?.preventDefault()

      const dealerFileResponse = await fetch(link)

      if (!dealerFileResponse.ok) {
        console.error('Failed to fetch DealerFile')
        return
      }

      const dealerFileBlob = await dealerFileResponse.blob()
      const dealerFileBlobUrl = URL.createObjectURL(dealerFileBlob)

      const linkElement = document.createElement('a')
      linkElement.href = dealerFileBlobUrl
      linkElement.download = await getDealerFileDownloadFileNameAsync()
      document.body.appendChild(linkElement)

      linkElement.click()

      document.body.removeChild(linkElement)
      URL.revokeObjectURL(dealerFileBlobUrl)
    } catch (error) {
      console.error(error)
    }
  }

  const menuCards = getMenuCards(handleDownloadDealerFileClickAsync)

  return (
    <S.Container>
      {menuCards.map(({ subtitle, cards }, index) => (
        <S.SectionContainer key={index}>
          <S.Subtitle>{subtitle}</S.Subtitle>
          <S.CardsContainer>
            {cards.map(
              (
                { iconName, title, link, external, handleDownloadClick },
                index
              ) => (
                <S.Card
                  to={external ? { pathname: link } : link}
                  target={external ? '_blank' : undefined}
                  key={index}
                  onClick={
                    handleDownloadClick
                      ? (event) => handleDownloadClick(link, event)
                      : undefined
                  }
                >
                  <S.CardTitleContainer>
                    <S.Icon icon={iconName} size={50} hasFill={false} />
                    <S.CardTitle>{title}</S.CardTitle>
                  </S.CardTitleContainer>
                  <S.CardDescription></S.CardDescription>
                </S.Card>
              )
            )}
          </S.CardsContainer>
        </S.SectionContainer>
      ))}
    </S.Container>
  )
}

export default SectionAdminMenus
